import { useState, useEffect } from "react";
import qrImg from "../assets/imgs/qrImg.png";
import { Col, Row, Container } from "react-bootstrap";
import QrReader from "react-qr-scanner";
import axios from "axios";
import serverPath from "./config";

function ListItemDetails(props) {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState("");
  const [owner, setOwner] = useState("");
  const [department, setDepartment] = useState("");
  const [result, setResult] = useState("");
  const [delay, setDelay] = useState(100);
  const [showQr, setShowQr] = useState(false);
  useEffect(() => {
    axios
      .get(`${serverPath}/api/buffet_order_details/${props.id}`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then(
        (response) => {
          setItem(response.data.order);
          setOwner(response.data.order.user);
          setDepartment(response.data.order.user.department.department);
        },
        (error) => {
          if (error.response) {
            props.showErr(error.response.data.message);
          } else {
            return false;
          }
        }
      );
  }, [loading]);
  const handleScan = (data) => {
    if (data) {
      setResult(data);
      props.qrCodeSet(data);
      props.close();
      props.setShowActionModal(true);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };
  //   lisiting normal orders
  const details =
    item.type === "normal" ? (
      item.order_details && item.order_details.length > 0 ? (
        item.order_details.map((order_item, index) => {
          const cart_items = JSON.parse(order_item.presit_details).cart
            .cart_items;
          if (order_item.order_preset) {
            return (
              <div key={index} className="order-details-item">
                <div className="header">
                  <img src={order_item.order_preset.image} />
                  <span>{order_item.order_preset.name}</span>
                </div>
                <div className="content">
                  <div className="order-content">
                    <div className="right-side">
                      <h4>محتويات المشروب</h4>
                      {cart_items.length > 0
                        ? cart_items.map((item, index) => {
                          return (
                            <div key={index} className="order-content-item">
                              {item.quantity1 + item.quantity2}
                              <span>
                                {item.item.name} ({item.item.label})
                              </span>
                            </div>
                          );
                        })
                        : null}
                    </div>
                    <div className="middle">
                      <h4>من المخزون</h4>
                      {cart_items.length > 0
                        ? cart_items.map((item, index) => {
                          return item.mine ? (
                            <span key={index} className="ismine">
                              <span>&#10003;</span>
                            </span>
                          ) : (
                            <span key={index} className="notmine"></span>
                          );
                        })
                        : null}
                    </div>
                    <div className="left-side">
                      <h4>الملاحظات</h4>
                      <div className="notes">
                        {JSON.parse(order_item.presit_details).cart.notes
                          ? JSON.parse(order_item.presit_details).cart.notes
                          : "لا توجد ملاحظات"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return <div>عفوا تم مسح المشروب من القائمة</div>;
          }
        })
      ) : null
    ) : (
      <div key="private" className="order-details-item">
        <div className="notes">
          <h3>طلب خاص</h3>
          <span>{item.details}</span>
        </div>
      </div>
    );
  // styling qr code
  const previewStyle = {
    height: 250,
    width: 250,
    objectFit: "cover",
  };

  const [showPopup, setShowPopup] = useState(false);
  const [password, setPassword] = useState("");

  const handlePopup = () => {
    setShowPopup(true);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    props.qrCodeSet(password);
    props.close();
    props.setShowActionModal(true);
    setShowPopup(false);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12} md={8}>
            <div className="details-wrapper">{details}</div>
          </Col>
          <Col xs={12} md={4}>
            <div className="userbox">
              <h6>مقدم الطلب : {owner.fullname}</h6>
              <h6> القسم : {department}</h6>
              {result}
            </div>
            <div className="qrCode">
              <button className="btn btn-success" onClick={handlePopup}>
                استلام الطلب
              </button>

              {showPopup && (
                <div className="popup">
                  <div className="popup-inner">
                    <h4>أدخل كلمة المرور</h4>
                    <input
                      type="password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <button className="btn btn-primary" onClick={handlePasswordSubmit}>
                      تأكيد
                    </button>
                    <button className="btn btn-secondary" onClick={() => setShowPopup(false)}>
                      إلغاء
                    </button>
                  </div>
                </div>
              )}

            </div>
          </Col>
        </Row>

      </Container>
    </>
  );
}
export default ListItemDetails;

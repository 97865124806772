function OrderSummeryList(props) {
  return (
    <table className="table" id="table">
      <thead>
        <tr>
          <th>الاسم</th>
          <th>العدد</th>
        </tr>
      </thead>
      <tbody>
        {props.items.length > 0 ? (
          props.items.map((item, index) => (
            <tr key={index}>
              <td>{item.employee}</td>
              <td>{item.total_orders}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="2"></td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default OrderSummeryList;

import { useState } from "react";
import ListItemDetails from "./ListItemDetails";
import { Modal, Alert } from "react-bootstrap";
import Moment from "moment";
import axios from "axios";
import serverPath from "./config";
function ListItem(props) {
  const [item] = useState(props.item);
  const [show, setShow] = useState(false);
  const [showRejectOrder, setShowRejectOrder] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [OrderMonthly, setOrderMonthly] = useState([]);
  const [result, setResult] = useState("");
  const [completeText, setCompleteText] = useState("");
  const [id, setId] = useState("");
  const [reason, setReason] = useState("");
  
  const openDetails = (sid, txt) => {
    setShow(true);
    setId(sid);
    setCompleteText(txt);
    // props.hideErr()
  };
  const closeModal = () => {
    setShow(!show);
  };

  const closeActionModal = () => {
    setShowActionModal(!showActionModal);
  };
const fetchOrderData = async () => {
  try {
    const response = await fetch(`${serverPath}/api/order_summery`); // Replace with your API endpoint
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const OrderMonthlyData = await response.json();
    console.log(OrderMonthlyData.data);
    
    setOrderMonthly(OrderMonthlyData.data);
    
    props.updateOrderSummaryList(OrderMonthlyData.data); // Update the order summary list
  } catch (error) {
    console.error('Error fetching order data:', error);
  }
};
  const approveOrder = async () => {
    await axios
      .post(
        `${serverPath}/api/accept_order`,
        {
          employee_qr_code: result,
          order_id: id,
        },
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then(
        (response) => {
          closeActionModal();
          props.approved(id);
          props.showErr("");
          // props.refreshApi();
        },
        (error) => {
          if (error.response) {
            props.showErr(error.response.data.message);
            setShowRejectOrder(false);
            setShowActionModal(false);
          } else {
            return false;
          }
        }
      );
  };
  const qrCodeSet = (data) => {   
    setResult(data);
  };
  const toggleRejectOrder = () => {
    setShowRejectOrder(!showRejectOrder);
    setShowActionModal(false);
  };
  const handleChange = (e) => {
    setReason(e.target.value);
  };
  const rejectOrder = () => {
    if (reason) {
      axios
        .post(
          `${serverPath}/api/reject_order`,
          {
            employee_qr_code: result,
            order_id: id,
            reason_of_rejection: reason,
          },
          {
            headers: {
              Accept: "application/json",
            },
          }
        )
        .then(
          (response) => {
            props.approved(id);
            setShowRejectOrder(false);
            props.showErr("");
          },
          (error) => {
            if (error.response) {
              props.showErr(error.response.data.message);
              setShowRejectOrder(false);
            } else {
              return false;
            }
          }
        );
    }
  };
  const completeOrder = async () => {
    await axios
      .post(
        `${serverPath}/api/complete_order`,
        {
          order_id: id,
          qr_code: result,
        },
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then(
        (response) => {
          //   props.refreshApi();
          props.delivered(id);
          closeActionModal();
          fetchOrderData();
        },
        (error) => {
          if (error.response) {
            props.showErr(error.response.data.message);
            closeActionModal();
          } else {
            return false;
          }
        }
      );
  };
  return (
    <>
      {props.type === "new" ? (
        <li onClick={() => openDetails(item.id)} className="list-item">
          <div>
            <h3>طلب رقم # {item.id}</h3>
            <span>
              {" "}
              استلام الطلب : {Moment(item.created_at).format("hh:mm A")}
            </span>
          </div>
        </li>
      ) : (
        <li>
          <div>
            <h3>طلب رقم # {item.id}</h3>
            <div className="list-meta">
              <span>
                {" "}
                تم بدء تنفيذ الطلب : {Moment(item.created_at).format("hh:mm A")}
              </span>
              <span> موظف البوفيه : {item.employee}</span>
            </div>
          </div>
          <button
            onClick={() => openDetails(item.id, "complete")}
            className="btn btn-success"
          >
            تم التنفيذ
          </button>
        </li>
      )}
      <Modal size="xl" animation={false} show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>طلب رقم # {item.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <ListItemDetails
            id={id}
            close={closeModal}
            closeActionModal={closeActionModal}
            setShowActionModal={setShowActionModal}
            qrCodeSet={qrCodeSet}
          />
        </Modal.Body>
      </Modal>
      <Modal
        className="sweet-modal"
        animation={false}
        show={showActionModal}
        onHide={closeActionModal}
      >
        <Modal.Body>
          {completeText === "complete"
            ? "هل تريد ان تقوم بتسليم الطلب؟"
            : "هل تريد ان تقوم بتنفيذ الطلب؟"}
        </Modal.Body>
        <Modal.Footer>
          {completeText === "complete" ? (
            <>
              <button onClick={completeOrder} className="btn btn-success">
                تم التنفيذ
              </button>
              <button onClick={closeActionModal} className="btn btn-danger">
                إلغاء
              </button>
            </>
          ) : (
            <>
              <button onClick={approveOrder} className="btn btn-success">
                قبول الطلب
              </button>
              <button onClick={toggleRejectOrder} className="btn btn-danger">
                رفض الطلب
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        className="sweet-modal"
        animation={false}
        show={showRejectOrder}
        onHide={() => setShowRejectOrder(false)}
      >
        <Modal.Body>
          سبب الرفض
          <form onSubmit={rejectOrder}>
            <input
              type="text"
              onChange={handleChange}
              required
              className="form-control"
              id="reason"
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={rejectOrder} className="btn btn-primary">
            إرسال
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default ListItem;
